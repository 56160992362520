import React from 'react';

import { Container, Row, Col } from 'reactstrap';

const LykeSection = () => {
	return (
		<div className='about-description text-center'>
			<div className='features-3'>
				<Container>
					<Row>
						<Col className='mr-auto ml-auto' md='8'>
							<h2 className='title' style={{ marginTop: '45px' }}>
								CO KRYJE SIĘ ZA{' '}
								<span style={{ color: '#024d9c', fontWeight: 'bold' }}>
									SENSOLYKE
								</span>
								?
							</h2>
							<h4 className='description'>
								Narzędzia do wieloobszarowego rozwoju ciała i umysłu oraz
								redukowania problemów behawioralnych. Pracując z dziećmi,
								zauważyliśmy korelację pomiędzy integracją sensoryczną, zdrowiem
								psychicznym i niezdrowymi tendencjami behawioralnymi. Na tej
								podstawie stworzyliśmy metodę SensoLYKE, która łączy i wspiera
								wszystkie te 3 obszary. SensoLyke to:
							</h4>
						</Col>
					</Row>
					<Row>
						<Col md='4'>
							<div className='info info-hover'>
								<div className='icon icon-azure icon-circle'>
									<i style={{ fontStyle: 'inherit' }}>1</i>
								</div>
								<h4 className='info-title'>ROZWÓJ EMOCJONALNY</h4>
								<p className='description'>
									Poprzez pracę z emocjami i zasobami dziecka, rozładowywanie
									napięcia, stresu, agresji, frustracji dziecka w zdrowy sposób.
								</p>
							</div>
						</Col>
						<Col md='4'>
							<div className='info info-hover'>
								<div className='icon icon-azure icon-circle'>
									<i style={{ fontStyle: 'inherit' }}>2</i>
								</div>
								<h4 className='info-title'>INTEGRACJA SENSORYCZNA</h4>
								<p className='description'>
									Poprzez Integrację Sensoryczną w praktyce, opartą przede
									wszystkim na ćwiczeniach motoryki dużej, wyciszeniu i
									relaksacji.
								</p>
							</div>
						</Col>
						<Col md='4'>
							<div className='info info-hover'>
								<div className='icon icon-azure icon-circle'>
									<i style={{ fontStyle: 'inherit' }}>3</i>
								</div>
								<h4 className='info-title'>ROZWÓJ PSYCHOFIZYCZNY</h4>
								<p className='description'>
									Poprzez neutralizowanie skutków przestymulowania wynikającego
									z dostępu do urządzeń elektronicznych, nauczania zdalnego i
									natłoku bodźców.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default LykeSection;
