import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import LykeFooter from './LykeFooter';
import LykeOpinion from './LykeOpinion';

const LykeAboutUs = () => {
	return (
		<>
			<div className='features-7 section-image'>
				<Col className='mr-auto ml-auto text-left' md='10' style={{textAlign: 'left'}}>
					<h2
						className='title offerTitle'
						style={{color: 'black', marginTop: '110px', zIndex: 111}}
					>
						<span style={{color: '#024d9c'}}>Sensolyke</span> to autorskie
						podejście do profilaktyki zdrowia psychicznego oparte na Integracji
						Sensorycznej i higienie zdrowia psychicznego.
					</h2>
					<p className='offerBoxDescription' style={{zIndex: 4, position: 'relative'}}>
						{/*<span style={{color: '#024d9c'}}>*/}
						{/*</span>{' '}*/}
						SensoLyke to także nowoczesne podejście do rozwoju sensorycznego, które
						przemienia codzienne zajęcia w pełne radości i zaangażowania
						przygody. Nasze produkowane w Polsce sensoryczne zestawy, pobudzają
						wyobraźnię, wspierają koncentrację i pomagają dzieciom odkrywać
						świat wszystkimi zmysłami. Dla nauczycieli to prawdziwe wsparcie -
						łatwe w zastosowaniu, ekologiczne narzędzia, które wzbogacają
						lekcje, przyciągają uwagę i budują lepszą atmosferę w grupie. SensoLyke
						rozwija dzieci i jednocześnie ułatwia pracę pedagogów, dając im
						więcej czasu na inspirujące nauczanie. Podejście SensoLyke
						zapewnia wieloobszarowe działania na które składają się:
					</p>
					{/*<p className='offerBoxDescription' style={{zIndex: 4, position: 'relative'}}>*/}
					{/*		SENSOLYKE*/}
					{/*	rozwija dzieci i jednocześnie ułatwia pracę pedagogów, dając im*/}
					{/*	więcej czasu na inspirujące nauczanie.*/}
					{/*</p>*/}
					{/*<p className='offerBoxDescription' style={{zIndex: 4, position: 'relative'}}>*/}
					{/*	*/}
					{/*</p>*/}
				</Col>
				<Container fluid>
					<Row>
						<Col className='px-0' md='6'>
							<Col sm='12' className='aboutUsText'>
								<div className='info info-horizontal info-hover'>
									<div className='icon icon-azure icon-circle'>
										<i
											className='now-ui-icons ui-2_favourite-28'
											style={{ color: 'rgb(4, 78, 160)' }}
										></i>
									</div>
									<div className='description'>
										<h5 className='info-title' style={{ color: 'black' }}>
											BUKINY
										</h5>
										<p className='description' style={{ color: '#3e3d3d' }}>
											To wyjątkowe, autorskie pomoce sensoryczne tworzone w
											Poznaniu. Służą zarówno dzieciom, jak i dorosłym. Ich
											użytkowanie jest w pełni intuicyjne, można też wspierać
											się scenariuszami i gotowymi ćwiczeniami. Zakres
											zastosowań i korzyści jest bardzo szeroki.
										</p>
									</div>
								</div>
								<div className='info info-horizontal info-hover'>
									<div className='icon icon-azure icon-circle'>
										<i
											className='now-ui-icons ui-2_settings-90'
											style={{ color: 'rgb(4, 78, 160)' }}
										></i>
									</div>
									<div className='description'>
										<h5 className='info-title' style={{ color: 'black' }}>
											WARSZTATY
										</h5>
										<p className='description' style={{ color: '#3e3d3d' }}>
											Wprowadzają w zagadnienia z zakresu Integracji
											Sensorycznej, wyjaśniają korelacje pomiędzy niezdrowymi
											nawykami behawioralnymi i nadmierną cyfryzacją, a rozwojem
											dzieci i wskazują drogę do treningu pożądanych postaw i
											umiejętności poprzez zabawę z Bukinami.
										</p>
									</div>
								</div>
								<div className='info info-horizontal info-hover'>
									<div className='icon icon-azure icon-circle'>
										<i
											className='now-ui-icons users_circle-08'
											style={{ color: 'rgb(4, 78, 160)' }}
										></i>
									</div>
									<div className='description'>
										<h5 className='info-title' style={{ color: 'black' }}>
											DORADZTWO
										</h5>
										<p className='description' style={{ color: '#3e3d3d' }}>
											Pomożemy Ci dobrać odpowiedni zestaw Bukinów oraz
											wyjaśnimy, jak wydobyć z nich największy potencjał.
											Przekażemy Ci gotowe ćwiczenia i zabawy z Bukinami, które
											najefektywniej spełnią Twoje potrzeby.
										</p>
									</div>
								</div>
							</Col>
						</Col>
						<Col md='6'>
							<div className='tabletImg'>
								<img
									alt='tablet ze sklepem sensolyke'
									src={require('assets/img/sensoCover.png')}
								></img>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<LykeFooter />
		</>
	);
};

export default LykeAboutUs;
