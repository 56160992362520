import '../assets/scss/now-ui-kit/styles.scss'; // Ścieżka do stylów
import React from 'react';

import LykeNavbar from './LykeNavbar';

import { Button, Container, Row, Col } from 'reactstrap';

import logo from '../assets/img/sensologo.png';

function LykeFooter() {
	React.useEffect(() => {
		document.body.classList.add('sidebar-collapse');
		document.documentElement.classList.remove('nav-open');
		document.body.scrollTop = 0;
		return function cleanup() {
			document.body.classList.remove('sidebar-collapse');
		};
	});
	return (
		<>
			<LykeNavbar />
			<hr className='footerHr' />
			<footer className='footer footer-white footer-big footerMobile'>
				<Container>
					<div>
						<Row>
							<Col md='3'>
								<img
									className='footer-logo'
									src={logo}
									alt='niebieskie logo sensolyke'
								></img>
							</Col>
							<Col md='3'>
								<h5 className='footerHeader'>KONTAKT</h5>
								<ul className='links-vertical'>
									<li>
										<span> 885 444 355</span>
									</li>
									<li>
										<span>biuro@sensolyke.pl</span>
									</li>
									<li>
										<span>Joachima Lelewela 6A,</span>
									</li>
									<li>
										<span>61-409 Poznań</span>
									</li>
								</ul>
							</Col>
							<Col md='3'>
								<h5 className='footerHeader'>DANE</h5>
								<ul className='links-vertical'>
									<li>
										<span> NASTAP TECH S.A.</span>
									</li>
									<li>
										<span>NIP 6060101216</span>
									</li>
									<li>
										<span>REGON 368568706</span>
									</li>
									<li>
										<span>KRS 0000699834</span>
									</li>
								</ul>
							</Col>
							<Col md='3'>
								<h5 className='footerHeader footerDocs'>DOKUMENTY</h5>
								<ul className='links-vertical'>
									<li>
										<a
											href='https://www.sensolyke.pl/regulamin.pdf'
											target='_blank'
											rel='noreferrer'
										>
											Regulamin serwisu
										</a>
									</li>
									<li>
										<a
											href='https://www.sensolyke.pl/polityka.pdf'
											target='_blank'
											rel='noreferrer'
										>
											Polityka prywatności
										</a>
									</li>
									<li>
										<a
											href='https://www.sensolyke.pl/OekoTex.pdf'
											target='_blank'
											rel='noreferrer'
										>
											CERTYFIKAT JAKOŚCI OEKO TEX
										</a>
									</li>
									<li>
										<a
											href='https://www.sensolyke.pl/certyfikateko.pdf'
											target='_blank'
											rel='noreferrer'
										>
											CERTYFIKAT JAKOŚCI EKO GWARANCJA
										</a>
									</li>
								</ul>
							</Col>
						</Row>
					</div>
					<hr></hr>
					<ul className='social-buttons social-buttons-align'>
						<li>
							<Button
								className='btn-icon btn-neutral'
								color='facebook'
								href='https://www.facebook.com/lyke.polska'
								size='lg'
								target='_blank'
							>
								<i className='fab fa-facebook-square'></i>
							</Button>
							<Button
								className='btn-icon btn-neutral'
								color='instagram'
								href='https://www.instagram.com/lyke.polska/'
								size='lg'
								target='_blank'
							>
								<i className='fab fa-instagram'></i>
							</Button>
						</li>
					</ul>
				</Container>
			</footer>
		</>
	);
}

export default LykeFooter;
