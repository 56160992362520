import '../assets/scss/now-ui-kit/styles.scss'; // Ścieżka do stylów
import React, { useState, useEffect } from 'react';

import { Col, Button } from 'reactstrap';
import LykeFAQ from './LykeFAQ';
import LykeFooter from './LykeFooter';

import { CiCircleCheck } from 'react-icons/ci';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { IoAlertCircleOutline } from 'react-icons/io5';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Modal from 'react-modal';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

Modal.setAppElement('body');

const LykeAboutUs = () => {
	const customStyles = {
		content: {
			// top: '50%',
			// left: '50%',
			// right: 'auto',
			// bottom: 'auto',
			// marginRight: '-50%',
			transform: 'translateY(5%)',
			paddingInline: '36px',
			paddingBlock: '48px',
			height: '100vh',
			width: '100vw',
			inset: '0px',
		},
	};

	let subtitle;
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [showOffer, setShowOffer] = React.useState(false);
	const [form, setForm] = React.useState({});
	const formChange = (e) => {
		const { name, value } = e.target;
		const tmp = { ...form };
		tmp[name] = value;
		setForm(tmp);
	};

	const sendMessage = () => {
		axios
			.post('https://netluck.pl:9030/sensolyke/mail', form)
			.then(() => {
				toast.success('Wiadomość wysłana');

				setForm({});
			})
			.catch(() => {
				toast.error('Wystąpił problem - prosimy spróbować później');
			});
	};

	function openModal() {
		setIsOpen(true);
		document.body.style.overflow = 'hidden';
	}

	function closeModal() {
		setIsOpen(false);
		document.body.style.overflow = 'auto';
	}

	const BasicProgram = (props) => {
		return (
			<>
				<p className={'pathTitle'} id={'adults'}>
					Zestaw SensoLyke Basic
				</p>
				<h4 className={'h4Offer'}>
					Zestawy SensoLyke Basic przeznaczone do zajęć w grupach do 25 osób.
					Zestaw wspomaga prowadzenie zajęć z elementami integracji
					sensorycznej, masażu głębokiego i relaksacji. Integralną częścią
					zestawu jest kurs z metody SensoLyke zamieszczony na platformie
					edukacyjnej.
				</h4>
				<div className={'pathContainer'}>
					<div className={'fullPath'}>
						<div className={'leftSection'}>
							<div className={'programTitleBox'}>
								<div
									style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
								>
									<span>
										<FaLongArrowAltRight classname ={'arrowRightLykeOffer'} />{' '}
									</span>
									<p>
										Zestaw do pracy w grupie{' '}
										<span style={{ fontWeight: 'bold' }}>25 osób</span> jaki i{' '}
										<span style={{ fontWeight: 'bold' }}>indywidualnej </span>
									</p>
								</div>
							</div>
							<div className={'programContainer containerWithBottomBorder'}>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
									</span>
									<p>Zestaw Basic - 15 pomocy sensorycznych </p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Relaksacja, masaż, odpoczynek</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Wspomaganie pracy układu nerwowego i propriocepcji </p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Wspieranie ekspresji myśli, emocji i potrzeb</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Wsparcie merytoryczne w formie materiałów i kursów</p>
								</div>
							</div>
							<div className={'offerImgContainer offerImgContainerMobile'}>
								<img
									className={'pathImg'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/basic.png')}
								></img>
							</div>
						</div>
						<div className={'offerImgContainerSmall'}>
							<img
								className={'pathImg'}
								style={{ alignSelf: 'flex-start' }}
								alt='zestaw'
								src={require('assets/img/basic.png')}
							></img>
						</div>
					</div>
				</div>
			</>
		);
	};

	const SportProgram = (props) => {
		return (
			<>
				<p className={'pathTitle'} id={'adults'}>
					Zestaw SensoLyke Sport
				</p>
				<h4 className={'h4Offer'}>
					Zestawy SensoLyke Sport przeznaczone do zajęć w grupach do 15 osób.
					Pozwala na wprowadzenie zajęć sportowych z elementami integracji
					sensorycznej co jest niezwykle korzystne dla dzieci w wieku
					przedszkolnym i wczesnoszkolnym, budując dobre nawyki od najmłodszych
					lat.{' '}
				</h4>
				<div className={'pathContainer'}>
					<div className={'fullPath'}>
						<div className={'leftSection'}>
							<div className={'programTitleBox'}>
								<div
									style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
								>
									<span>
										<FaLongArrowAltRight classname ={'arrowRightLykeOffer'} />{' '}
									</span>
									<p>
										Zestaw do pracy w grupie{' '}
										<span style={{ fontWeight: 'bold' }}>15 osób</span> jaki i{' '}
										<span style={{ fontWeight: 'bold' }}>indywidualnej </span>
									</p>
								</div>
							</div>
							<div className={'programContainer containerWithBottomBorder'}>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Sportowe pobudzenie każdej partii mięśniowej.</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Wspieranie ekspresji myśli, emocji i potrzeb</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Poprawa integracji ciała i umysłu</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Rozwój umiejętności komunikacyjnych</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Wsparcie merytoryczne w formie materiałów i kursów</p>
								</div>
							</div>
							<div className={'offerImgContainer offerImgContainerMobile'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw sport'
									src={require('assets/img/sportpath.png')}
								></img>
							</div>
						</div>
						<div className={'offerImgContainerSport'}>
							<img
								className={'pathImg pathImgSport'}
								style={{ alignSelf: 'flex-start' }}
								alt='zestaw sport'
								src={require('assets/img/sportpath.png')}
							></img>
						</div>
					</div>
				</div>
			</>
		);
	};

	const PathProgram = (props) => {
		return (
			<>
				<p className={'pathTitle'} id={'adults'}>
					Zestaw SensoLyke Ścieżka Sensoryczna
				</p>
				<h4 className={'h4Offer'}>
					Zestawy SensoLyke Ścieżka przeznaczone do zajęć w grupach do 15 osób.
					To specjalnie zaprojektowane pomoce sensoryczne i zestaw ćwiczeń,
					które angażują różne zmysły i umożliwiają dzieciom doświadczanie
					różnorodnych bodźców sensorycznych w kontrolo­wany sposób
				</h4>

				<div className={'pathContainer'}>
					<div className={'fullPath'}>
						<div className={'leftSection'}>
							<div className={'programTitleBox'}>
								<div
									style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
								>
									<span>
										<FaLongArrowAltRight classname ={'arrowRightLykeOffer'} />{' '}
									</span>
									<p>
										Zestaw do pracy w grupie{' '}
										<span style={{ fontWeight: 'bold' }}>15 osób</span> jaki i{' '}
										<span style={{ fontWeight: 'bold' }}>indywidualnej </span>
									</p>
								</div>
							</div>
							<div className={'programContainer containerWithBottomBorder'}>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Koordynacja ciała, wspieranie pracy stawów i kręgosłupa</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Wspieranie ekspresji myśli, emocji i potrzeb</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Rozwijanie umiejętności poznawczych</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Rozwój umiejętności przetwarzania bodźców</p>
								</div>
								<div className={'textWithIcon'}>
									<span>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
									</span>
									<p>Wsparcie merytoryczne w formie materiałów i kursów</p>
								</div>
							</div>
							<div className={'offerImgContainer offerImgContainerMobile'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/sciezka.png')}
								></img>
							</div>
						</div>
						<div className={'offerImgContainerSport'}>
							<img
								className={'pathImg pathImgPath'}
								style={{ alignSelf: 'flex-start' }}
								alt='zestaw'
								src={require('assets/img/sciezka.png')}
							></img>
						</div>
					</div>
				</div>
			</>
		);
	};

	const [program, setProgram] = useState('');
	const [details, setDetails] = useState([]);
	const [checked, setChecked] = React.useState(false);

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	const checkboxHandler = (value) => {
		setShowOffer(false);
		if (details.includes(value)) {
			setDetails((oldValues) => {
				return oldValues.filter((item) => item !== value);
			});
		} else {
			setDetails([...details, value]);
		}
	};

	useEffect(() => {
		if (details.length > 0 && program) {
			setForm({
				message: `Program: ${program}, ${`opcja: ${details.join(', ')}`}`,
			});
		} else if (
			details.length === 0 &&
			(program === 'Programy dla seniorow' ||
				program === 'Programy dla rodzicow')
		) {
			setForm({ message: `Program: ${program}` });
		}
	}, [details, program]);

	const programHandler = (details) => {
		if (details.length === 0 && program === 'Programy dla rodzicow') {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '24px',
						marginTop: '48px',
					}}
				>
					<p className={'pathTitle'} id={'adults'}>
						Zestaw SensoLyke Tulisie
					</p>

					<div className={'pathContainer'}>
						<div className={'fullPath'}>
							<div className={'leftSection'}>
								<div className={'programContainer containerWithBottomBorder'}>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Masaż relaksacyjny i obciążeniowy </p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Wspomaganie pracy układu nerwowego i propiocepcji</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>
											Ćwiczenie koordynacji ciała, wspieranie pracy stawów i
											kręgosłupa
										</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Wspieranie ekspresji myśli, emocji, potrzeb</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Zabawy rozwijające zmysł wzroku i słuchu</p>
									</div>
								</div>
								<div className={'offerImgContainer offerImgContainerMobile'}>
									<img
										className={'pathImg pathImgLower'}
										style={{ alignSelf: 'flex-start' }}
										alt='zestaw'
										src={require('assets/img/tulisie.png')}
									></img>
								</div>
							</div>
							<div className={'offerImgContainer'}>
								<img
									className={'pathImg pathImgTulisie'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/tulisie.png')}
								></img>
							</div>
						</div>
					</div>

					<p className={'pathTitle'} id={'adults'}>
						Zestaw SensoLyke Harmonia
					</p>

					<div className={'pathContainer'}>
						<div className={'fullPath'}>
							<div className={'leftSection'}>
								<div className={'programContainer containerWithBottomBorder'}>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Ćwiczenie motoryki dłoni i stóp</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Ćwiczenie koordynacji ręka - oko</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Wspomaganie pracy układu nerwowego i propiocepcji</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Sportowa aktywacja całego ciała</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Masaż obciążeniowy</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Ćwiczenie koncentracji</p>
									</div>
								</div>
							</div>
							<div className={'offerImgContainerMobile'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/harmonia.png')}
								></img>
							</div>
							<div className={'offerImgContainer'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/harmonia.png')}
								></img>
							</div>
						</div>
					</div>
				</div>
			);
		}

		if (details.length === 0 && program === 'Programy dla seniorow') {
			return (
				<>
					<p className={'pathTitle'} id={'adults'}>
						{' '}
						Zestaw SensoLyke Relax
					</p>
					<h4 className={'h4Offer'}>
						{' '}
						Zestaw ćwiczeń z pomocami sensorycznymi został opracowany z myślą o
						dojrzałych użytkownikach, wspierając ich zdrowie, samopoczucie i
						kondycję. Łączy łagodne ćwiczenia, które wzmacniają mięśnie,
						poprawiają elastyczność i koordynację ruchową, minimalizując ryzyko
						kontuzji.
					</h4>

					<div className={'pathContainer'}>
						<div className={'fullPath'}>
							<div className={'leftSection'}>
								<div className={'programTitleBox'}>
									<div
										style={{
											display: 'flex',
											gap: '8px',
											alignItems: 'center',
										}}
									>
										<span>
											<FaLongArrowAltRight classname ={'arrowRightLykeOffer'} />
										</span>
										<p>
											Zestaw na{' '}
											<span style={{ fontWeight: 'bold' }}>5 stanowisk </span>+
											1 zestaw dla prowadzącego zajęcia
										</p>
									</div>
								</div>
								<div className={'programContainer containerWithBottomBorder'}>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Wsparcie merytoryczne w formie materiałów i kursów</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Szkolenie stacjonarne personelu dla 2 osób z gminy </p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Relaksacja, masaż, odpoczynek</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Wspomaganie pracy układu nerwowego i propriocepcji</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>
											Koordynacja ciała, wspieranie pracy stawów i kręgosłupa
										</p>
									</div>
								</div>
							</div>
							<div
								className={
									'offerImgContainer offerImgContainerLower relaxPathImg'
								}
							>
								<img
									className={'pathImg'}
									alt='zestaw'
									src={require('assets/img/relaxpath.png')}
								></img>
							</div>
						</div>
					</div>
				</>
			);
		}
		if (details.includes('Zajecia ogolno rozwojowe dla terapeutow')) {
			return <BasicProgram form />;
		} else if (details.includes('Zajecia indywidualne')) {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '24px',
						marginTop: '48px',
					}}
				>
					<h4 className={'h4Offer'}>
						Zestawy pomocy SensoLyke przeznaczone do pracy indywidualnej lub w
						małych grupach, doskonale sprawdzają się w gabinetach
						terapeutycznych, wspierając terapię integracji sensorycznej.
						Oferujemy również wsparcie w postaci propozycji ćwiczeń, które
						pomagają w efektywnej pracy z dziećmi.
					</h4>

					<p className={'pathTitle'} id={'adults'}>
						Zestaw SensoLyke S
					</p>

					<div className={'pathContainer'}>
						<div className={'fullPath'}>
							<div className={'leftSection'}>
								<div className={'programTitleBox'}>
									<div
										style={{
											display: 'flex',
											gap: '8px',
											alignItems: 'center',
										}}
									>
										<span>
											<FaLongArrowAltRight classname ={'arrowRightLykeOffer'} />{' '}
										</span>
										<p>
											Zestaw do pracy{' '}
											<span style={{ fontWeight: 'bold' }}>indywidualnej </span>{' '}
											lub w grupie do{' '}
											<span style={{ fontWeight: 'bold' }}>3 osób </span>
										</p>
									</div>
								</div>
								<div className={'programContainer containerWithBottomBorder'}>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Zestaw bałwanek i foka - 5 pomocy sensorycznych </p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Wyciszenie, masaż, odpoczynek</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>
											Regulacja napięcia mięśniowego i nadwrażliwości
											sensorycznej
										</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Wspomaganie terapii dotykowej priopriocepcyjnej</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<span>
												<CiCircleCheck className={'ciCircleCheck'} />
											</span>
										</span>
										<p>Wsparcie merytoryczne w formie materiałów i kursów</p>
									</div>
								</div>
								<div className={'offerImgContainer offerImgContainerMobile'}>
									<img
										className={'pathImg pathImgLower'}
										style={{ alignSelf: 'flex-start' }}
										alt='zestaw'
										src={require('assets/img/seal.png')}
									></img>
								</div>
							</div>
							<div className={'offerImgContainer'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/seal.png')}
								></img>
							</div>
						</div>
					</div>

					<p className={'pathTitle'} id={'adults'}>
						Zestaw SensoLyke M
					</p>

					<div className={'pathContainer'}>
						<div className={'fullPath'}>
							<div className={'leftSection'}>
								<div className={'programTitleBox'}>
									<div
										style={{
											display: 'flex',
											gap: '8px',
											alignItems: 'center',
										}}
									>
										<span>
											<FaLongArrowAltRight classname ={'arrowRightLykeOffer'} />
										</span>
										<p>
											Zestaw do pracy{' '}
											<span style={{ fontWeight: 'bold' }}>indywidualnej </span>{' '}
											lub w grupie do{' '}
											<span style={{ fontWeight: 'bold' }}>5 osób </span>
										</p>
									</div>
								</div>
								<div className={'programContainer containerWithBottomBorder'}>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Zestaw krokodyl i foka - 6 pomocy sensorycznych </p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Relaksacja, masaż, odpoczynek</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Regulacja napięcia mięśniowego</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Wspomaganie priopriocepcji</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Wsparcie merytoryczne w formie materiałów i kursów</p>
									</div>
								</div>
							</div>
							<div className={'offerImgContainerMobile'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/crocodile.png')}
								></img>
							</div>
							<div className={'offerImgContainer'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/crocodile.png')}
								></img>
							</div>
						</div>
					</div>

					<p className={'pathTitle'} id={'adults'}>
						Zestaw SensoLyke L
					</p>
					<div className={'pathContainer'}>
						<div className={'fullPath'}>
							<div className={'leftSection'}>
								<div className={'programTitleBox'}>
									<div
										style={{
											display: 'flex',
											gap: '8px',
											alignItems: 'center',
										}}
									>
										<span>
											<FaLongArrowAltRight classname ={'arrowRightLykeOffer'} />
										</span>
										<p>
											Zestaw do pracy{' '}
											<span style={{ fontWeight: 'bold' }}>indywidualnej </span>{' '}
											lub w grupie do{' '}
											<span style={{ fontWeight: 'bold' }}>5 osób </span>
										</p>
									</div>
								</div>
								<div className={'programContainer containerWithBottomBorder'}>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Zestaw smok i foka - 6 pomocy sensorycznych </p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Relaksacja, masaż głęboki, odpoczynek</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>
											Regulacja napięcia mięśniowego i nadwrażliwości
											sensorycznej
										</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Wspomaganie priopriocepcji</p>
									</div>
									<div className={'textWithIcon'}>
										<span>
											<CiCircleCheck className={'ciCircleCheck'} />
										</span>
										<p>Wsparcie merytoryczne w formie materiałów i kursów</p>
									</div>
								</div>
							</div>
							<div className={'offerImgContainerMobile'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/dragon.png')}
								></img>
							</div>
							<div className={'offerImgContainer'}>
								<img
									className={'pathImg pathImgLower'}
									style={{ alignSelf: 'flex-start' }}
									alt='zestaw'
									src={require('assets/img/dragon.png')}
								></img>
							</div>
						</div>
					</div>
				</div>
			);
		} else if (
			details.includes('Zajecia ogolnorozwojowe') &&
			details.length === 1
		) {
			return <BasicProgram form />;
		} else if (details.includes('Sport') && details.length === 1) {
			return <SportProgram form />;
		} else if (details.includes('Sciezka') && details.length === 1) {
			return <PathProgram form />;
		} else if (
			details.includes('Zajecia ogolnorozwojowe') &&
			details.includes('Sport') &&
			details.length === 2
		) {
			return (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
					<BasicProgram />
					<SportProgram form />
				</div>
			);
		} else if (
			details.includes('Zajecia ogolnorozwojowe') &&
			details.includes('Sciezka') &&
			details.length === 2
		) {
			return (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
					<BasicProgram />
					<PathProgram form />
				</div>
			);
		} else if (
			details.includes('Sport') &&
			details.includes('Sciezka') &&
			details.length === 2
		) {
			return (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
					<SportProgram />
					<PathProgram form />
				</div>
			);
		} else if (
			details.includes('Zajecia ogolnorozwojowe') &&
			details.includes('Sport') &&
			details.includes('Sciezka') &&
			details.length === 3
		) {
			return (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
					<BasicProgram />
					<SportProgram />
					<PathProgram form />
				</div>
			);
		}
	};

	const onProgramOptionChangeHandler = (e) => {
		setShowOffer(false);
		setProgram(e.target.value);
		details.length = 0;
	};

	const onDetailsOptionChangeHandler = (e) => {
		setShowOffer(false);
		details.length = 0;
		setDetails([...details, e.target.value]);
	};

	return (
		<>
			<Col className='mr-auto ml-auto text-center' md='10'>
				<h2
					className='title offerTitle'
					style={{ color: 'black', marginTop: '110px', zIndex: 0 }}
				>
					<span style={{ color: '#024d9c' }}>Sensolyke</span> to autorskie
					podejście do profilaktyki zdrowia psychicznego oparte na Integracji
					Sensorycznej i higienie zdrowia psychicznego.
				</h2>
			</Col>
			<Col className='mr-auto ml-auto text-center' md='10'>
				<div className={'offerSection'}>
					<div className={'offerBox'}>
						<CiCircleCheck
							style={{ margin: '0 auto' }}
							size={64}
							color={'#024D9C'}
						/>

						<h4 className={'offerBoxTitle'}>METODA</h4>
						<p className={'offerBoxDescription'}>
							Metoda SensoLyke to autorskie podejście do rozwoju, oparte na
							Integracji Sensorycznej i higienie zdrowia psychicznego. Wspieraj
							równowagę emocjonalną i fizyczną, pomagając użytkownikom lepiej
							radzić sobie ze stresem i wyzwaniami codziennego życia.
						</p>
					</div>
					<div className={'offerBox'}>
						<CiCircleCheck
							style={{ margin: '0 auto' }}
							size={64}
							color={'#024D9C'}
						/>
						<h4 className={'offerBoxTitle'}>WARSZTATY</h4>
						<p className={'offerBoxDescription'}>
							Dostępne warsztaty pomagają zrozumieć wpływ niezdrowych nawyków i
							nadmiernej cyfryzacji na rozwój dzieci. Zestawy ćwiczeń z Bukinami
							pokazują, jak poprzez zabawę wspierać zdrowe postawy i kluczowe
							umiejętności.
						</p>
					</div>
					<div className={'offerBox'}>
						<CiCircleCheck
							style={{ margin: '0 auto' }}
							size={64}
							color={'#024D9C'}
						/>
						<h4 className={'offerBoxTitle'}>NARZĘDZIA</h4>
						<p className={'offerBoxDescription'}>
							Bukiny to unikalne pomoce sensoryczne. Wykonane z naturalnych
							materiałów, pobudzają zmysły, wspierają koncentrację i rozwijają
							kreatywność. Idealne do zabawy, ćwiczeń i relaksu, są łatwe w
							użyciu zarówno w domu, jak i w placówkach edukacyjnych.{' '}
						</p>
					</div>
				</div>
				<div className='form-box'>
					<form
						className={'my-form form'}
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '16px',
							width: 'fit-content',
							margin: '0 auto',
						}}
					>
						<span className='form-title'>
							Dopasuj ofertę do swoich potrzeb.
						</span>
						<span className='form-subtitle'>
							Wybierz interesujące Cię opcje, a otrzymasz idealnie dopasowany
							zestaw SensoLyke
						</span>
						<div className={'sensoFormContainer'}>
							<>
								<div className={'radioContainer'}>
									<div className={'onceCheckboxContainer'}>
										<input
											name={'program'}
											id={'ogolnorozwojowe'}
											type={'radio'}
											onChange={(e) => onProgramOptionChangeHandler(e)}
											value={'Programy dla Terapeutow'}
										/>
										<label htmlFor={'ogolnorozwojowe'}>
											Programy dla Terapeutów
										</label>
									</div>
									<div className={'onceCheckboxContainer'}>
										<input
											name={'program'}
											id={'dzieci'}
											type={'radio'}
											onChange={(e) => onProgramOptionChangeHandler(e)}
											value={'Programy dla placowek oswiatowych'}
										/>
										<label htmlFor={'dzieci'}>
											Programy dla Placówek Oświatowych
										</label>
									</div>
									<div className={'onceCheckboxContainer'}>
										<input
											name={'program'}
											id={'dorosli'}
											type={'radio'}
											onChange={(e) => onProgramOptionChangeHandler(e)}
											value={'Programy dla seniorow'}
										/>
										<label htmlFor={'dorosli'}>Programy dla Seniorów</label>
									</div>
									<div className={'onceCheckboxContainer'}>
										<input
											name={'program'}
											id={'rodzice'}
											type={'radio'}
											onChange={(e) => onProgramOptionChangeHandler(e)}
											value={'Programy dla rodzicow'}
										/>
										<label htmlFor={'rodzice'}>Programy dla Rodziców</label>
									</div>
								</div>

								{program === 'Programy dla Terapeutow' && (
									<>
										<p className='form-subtitle' style={{ marginBlock: '4px' }}>
											Wybierz rodzaj zajęć
										</p>
										<div className={'radioContainer'}>
											<div className={'onceCheckboxContainer'}>
												<input
													name={'program-gabinet'}
													id={'gabinety-ogolno'}
													type={'radio'}
													onChange={(e) => onDetailsOptionChangeHandler(e)}
													value={'Zajecia ogolno rozwojowe dla terapeutow'}
												/>
												<label htmlFor={'gabinety-ogolno'}>
													Zajęcia ogólnorozwojowe
												</label>
											</div>
											<div className={'onceCheckboxContainer'}>
												<input
													name={'program-gabinet'}
													id={'gabinety-indywidualne'}
													type={'radio'}
													onChange={(e) => onDetailsOptionChangeHandler(e)}
													value={'Zajecia indywidualne'}
												/>
												<label htmlFor={'gabinety-indywidualne'}>
													Zajęcia indywidualne
												</label>
											</div>
											{/*<select onChange={(e) => onDetailsOptionChangeHandler(e)}>*/}
											{/*  <option value={"Zajecia ogolno rozwojowe dla gabinetu"} label={"Zajęcia ogólno-rozwojowe"}/>*/}
											{/*  <option value={"Zajecia indywidualne"} label={"Indywidualne"}/>*/}
											{/*</select>*/}
										</div>
									</>
								)}

								{program === 'Programy dla placowek oswiatowych' && (
									<>
										<p className='form-subtitle' style={{ marginBlock: '4px' }}>
											Wybierz rodzaj zajęć
										</p>
										<div className={'checkboxesContainer'}>
											<div className={'onceCheckboxContainer'}>
												<input
													id={'ogolno'}
													type={'checkbox'}
													onChange={(e) => checkboxHandler(e.target.value)}
													value={'Zajecia ogolnorozwojowe'}
												/>
												<label htmlFor={'ogolno'}>
													Zajęcia ogólnorozwojowe
												</label>
											</div>
											<div className={'onceCheckboxContainer'}>
												<input
													id={'sport'}
													onChange={(e) => checkboxHandler(e.target.value)}
													type={'checkbox'}
													value={'Sport'}
												/>
												<label htmlFor={'sport'}>Zajęcia sportowe</label>
											</div>
											<div className={'onceCheckboxContainer'}>
												<input
													id={'sciezka'}
													onChange={(e) => checkboxHandler(e.target.value)}
													type={'checkbox'}
													value={'Sciezka'}
												/>
												<label htmlFor={'sciezka'}>
													Zajęcia na ścieżce sensorycznej
												</label>
											</div>
										</div>
									</>
								)}
							</>
						</div>
					</form>
					<button
						className={'showOfferBtn'}
						onClick={() => setShowOffer(true)}
						disabled={
							details.length > 0 ||
							program === 'Programy dla seniorow' ||
							program === 'Programy dla rodzicow'
								? false
								: true
						}
					>
						Pokaż ofertę
					</button>
				</div>

				<div style={{ display: showOffer ? 'block' : 'none' }}>
					{programHandler(details)}
					<div className={'contactOfferContainer'}>
						<p className='interested'>
							{' '}
							Jesteś zainteresowany/a? Zostaw nam swój adres e-mail i wrócimy do
							Ciebie z ofertą
						</p>
						<form>
							<div className={'form-input-div '}>
								<div className='coolinput'>
									{/*<label for="input" class="text">Email:</label>*/}
									<input
										type='text'
										placeholder='Imię'
										name='name'
										className='input'
										onChange={formChange}
									/>
									<input
										type='text'
										placeholder='Nazwisko'
										name='lastname'
										className='input'
										onChange={formChange}
									/>
									<input
										placeholder='Adres email'
										name='email'
										className='input'
										type='email'
										onChange={formChange}
									/>
								</div>
								<Button
									className={'showOfferBtn buttonForm'}
									style={{ marginTop: '12px' }}
									disabled={
										form.name && form.lastname && form.email ? false : true
									}
									onClick={sendMessage}
								>
									Wyślij zapytanie
								</Button>
							</div>
						</form>
					</div>
				</div>

				<div className={'borderDiv'} style={{ marginBottom: '24px' }}>
					<IoAlertCircleOutline
						size={64}
						style={{
							margin: '0 auto',
							marginTop: '16px',
							marginBottom: '-16px',
						}}
						color={'#024D9C'}
					/>
					<h4
						style={{
							color: 'black',
							fontWeight: '300',
							fontSize: '16px',
							textAlign: 'left',
						}}
					>
						Integralną częścią każdego zestawu jest dedykowany Kurs Sensolyke,
						który nie tylko ułatwia prowadzenie zajęć z Integracji Sensorycznej,
						ale także wspiera rozwój dzieci w wielu obszarach. Ćwiczenia
						przedstawione w kursie pozwalają skupić się na kształtowaniu
						koordynacji wzrokowo-ruchowej oraz motoryki dużej, co ma pozytywny
						wpływ na ogólny rozwój dziecka. Wspomagają budowę mięśni niezbędnych
						do utrzymania prawidłowej postawy i wykonania precyzyjnych ruchów.
					</h4>
					<p
						style={{
							color: 'black',
							fontSize: '16px',
							fontWeight: '300',
							marginTop: '8px',
							textAlign: 'left',
						}}
					>
						Pomożemy Ci dobrać odpowiedni zestaw Bukinów oraz wyjaśnimy, jak
						wydobyć z nich największy potencjał. Przekażemy Ci gotowe ćwiczenia
						i zabawy z Bukinami, które najefektywniej spełnią Twoje potrzeby.
					</p>
				</div>

				{/*<div className={"offerHelp"}>*/}
				{/*  <IoAlertCircleOutline*/}
				{/*      size={64}*/}
				{/*      style={{margin: "0 auto"}}*/}
				{/*      color={"#024D9C"}*/}
				{/*  />*/}
				{/*</div>*/}
				<div style={{ paddingBlock: '32px' }}>
					<Carousel responsive={responsive}>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel1.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel2.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel3.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel4.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel5.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel6.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel7.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel8.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel9.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel10.jpg')}
							/>
						</div>
						<div style={{ marginRight: '12px' }}>
							<img
								style={{ borderRadius: '8px' }}
								alt='dzieci bawiace sie zestawem sensolyke'
								src={require('assets/img/karuzel11.jpg')}
							/>
						</div>
					</Carousel>
				</div>
			</Col>
			<LykeFAQ />
			<LykeFooter />
			<Toaster />
		</>
	);
};

export default LykeAboutUs;
