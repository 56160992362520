import React from 'react';

import { MapContainer, TileLayer, Marker, ZoomControl } from 'react-leaflet';
import L, { Icon } from 'leaflet';
import '../assets/css/mapOfCustomers.css';

let officeIcon = <Icon />;
officeIcon = new L.Icon({
	iconUrl: '/static/sblueMarker.png',
	iconSize: [34, 50],
});

const LykeContactMap = () => {
	return (
		<div style={{ height: '20%' }}>
			<MapContainer
				className='markercluster-map2'
				center={[52.3599395, 16.8861318]}
				zoom={18}
				minZoom={18}
				maxZoom={18}
				scrollWheelZoom={true}
				zoomControl={false}
			>
				<ZoomControl position='bottomright' />
				<TileLayer
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
				<Marker position={[52.3599395, 16.8861318]} icon={officeIcon}></Marker>
			</MapContainer>
		</div>
	);
};

export default LykeContactMap;
